/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useSelectedReport } from 'store/hooks'
import { useIsConnected } from 'helpers/hooks/useIsConnected'
import TravelerService from 'services/TravelerService'

/* Component imports -------------------------------------------------------- */
import {
  Badge,
  Button,
  Dialog,
  DialogTitle,
} from '@mui/material'
import {
  SaveRounded,
  SyncRounded,
} from '@mui/icons-material'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import CloseButton from 'components/CloseButton/CloseButton'
import ShowMoreLessText from 'components/ShowMoreLessText/ShowMoreLessText'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material'
import type { FormikErrors } from 'formik'
import type {
  TravelerForm,
  TravelerAndUtils,
} from 'types/TravelerForm'
import type {
  ActeurTraveller,
  CodeLabel,
  Garantie,
  Indemnisation,
  IndemnisationTraveller,
  PersonnePiece,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface TravelerIconButtonProps {
  isSaving: boolean;
}

const TravelerIconButton = styled.div<TravelerIconButtonProps>`
  margin-left: 13px;
  margin-right: 0px;

  button {
    margin-left: 0;
    margin-right: 0;
  }

  span {
    font-weight: bold;
  }

  svg {
    animation: ${(props) => props.isSaving ? 'rotation 2s infinite linear reverse' : 'none'};
  }
`

const BadgeHover = styled(Badge)`
  span {
    :hover {
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 10px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ErrorMessageSeparatorContainer = styled.div`
  width: 100%;
  padding: 0 10px 10px 20px;
`

const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface ReportSyncStatusButtonProps extends ButtonProps {
  formikForm: TravelerForm;
  errorForm: TravelerForm;
}

const ReportSyncStatusButton: React.FC<ReportSyncStatusButtonProps> = ({ formikForm, errorForm }) => {
  const navigate = useNavigate()
  const isConnected = useIsConnected()
  const { caseId = '' } = useParams<{ caseId: string }>()
  const selectedReport: string | null = useSelectedReport(caseId)
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false)
  const [ openModal, setOpenModal ] = useState<boolean>(false)

  const formErrors = useMemo(() => {
    const messages: {msg: string; url: string}[] = []

    const keys = Object.keys(errorForm.errors).filter((error) => !error.startsWith('new') && !error.startsWith('irsiData')) as (keyof FormikErrors<TravelerAndUtils>)[]
    keys.forEach((key) => {
      if (key === 'acteurs') {
        const acteurs: ActeurTraveller[] = errorForm.errors[key] as unknown as ActeurTraveller[]
        acteurs.forEach((acteur, index) => {
          if (acteur) {
            const personName: string = formikForm.values.acteurs[index].nom;

            (Object.keys(acteur) as Array<keyof ActeurTraveller>).forEach((acteurKey) => {
              const acteurError = (errorForm.errors[key]?.[index] as FormikErrors<ActeurTraveller>)[acteurKey]
              if (acteurKey === 'intervenants') {
                //
              }
              if (acteurKey === 'pieces') {
                const pieces = (errorForm.errors[key]?.[index] as FormikErrors<ActeurTraveller>).pieces as unknown as PersonnePiece[]
                pieces.forEach((piece) => {
                  piece && (Object.keys(piece) as Array<keyof PersonnePiece>).forEach((pieceKey) => {
                    if (typeof piece[pieceKey] === 'string') {
                      messages.push({ msg: `<b>${personName} - </b> <i>${piece[pieceKey] as string}</i>`, url: 'indemnisation' })
                    }
                  })
                })
              }
              else if (acteurKey === 'indemnisation') {
                const compensationErrors = acteurError as FormikErrors<IndemnisationTraveller>
                if (compensationErrors.indemnisations) {
                  const compensations = compensationErrors.indemnisations as unknown as Indemnisation[]
                  compensations?.forEach((indemn, index) => {
                    if (indemn.garantie) {
                      messages.push({ msg: `<b>${personName} - </b> <i>Indemnisation ${index + 1} : La garantie est manquante.</i>`, url: 'indemnisation' })
                    } else {
                      messages.push({ msg: `<b>${personName} - </b> <i>Il y a un problème avec l'indemnisation ${index + 1} de cette personne.</i>`, url: 'indemnisation' })
                    }
                  })
                } else {
                  messages.push({ msg: `<b>${personName} - </b> <i>Il y a un problème avec les indemnisations de cette personne.</i>`, url: 'indemnisation' })
                }
              }
              else if ((acteurError as CodeLabel)?.code !== undefined) {
                messages.push({
                  msg: `${personName} : ${acteurKey} : ${(acteurError as CodeLabel)?.code}`,
                  url: `modification/intervenants/${formikForm.values.acteurs[index].id}`,
                })
              }
              else if (typeof acteurError === 'string') {
                messages.push({
                  msg: `${personName} : ${acteurKey} : ${acteurError}`,
                  url: `modification/intervenants/${formikForm.values.acteurs[index].id}`,
                })
              } else {
                messages.push({ msg: `<b>${personName} - </b> <i>Il y a un problème avec cette personne.</i>`, url: 'intervenants' })
              }
            })
          }
        })
      }
      if (key === 'garanties') {
        const garanties = errorForm.errors[key] as unknown as Garantie[]
        garanties.forEach((garantie, index) => {
          (Object.keys(garantie) as Array<keyof Garantie>).forEach((garantieKey) => {
            if (typeof garantie[garantieKey] === 'string') {
              messages.push({ msg: `<b>Garantie ${index + 1} - </b> <i>${garantie[garantieKey] as string}</i>`, url: 'indemnisation' })
            }
            else {
              messages.push({ msg: `<b>Garantie ${index + 1} - </b> <i>${(garantie[garantieKey] as CodeLabel).libelle}</i>`, url: 'indemnisation' })
            }
          })
        })
      }
      if (key === 'etat') {
        messages.push({ msg: `<b>Le serveur à rencontré une erreur durant la sauvegarde. Essayez de contacter les développeurs.</b>`, url: '' })
      }
      if (key === 'sinistre') {
        messages.push({ msg: `<b>Il semble y avoir un problème avec le sinistre.</b>`, url: 'sinistre' })
      }
      else {
        messages.push({ msg: `<b>Il semble y avoir un problème, merci de contacter un administrateur.</b>`, url: '' })
      }
    })
    return messages
  }, [ errorForm.errors ])

  const onBadgeClick = () => {
    if (formErrors.length > 0) {
      setOpenModal(true)
    }
  }

  const onIconClick: React.MouseEventHandler<HTMLButtonElement>= (event) => {
    event.stopPropagation()
    TravelerService.submitTraveler({
      formikForm,
      reportId: selectedReport !== null ? selectedReport : `${caseId}-1`,
      caseId,
      validation: true,
      setIsSubmitting,
      errorForm,
    })
  }

  const onButtonClick = (url: string) => {
    navigate(`/dossiers/${caseId}/traveller/${url}`)
    setOpenModal(false)
  }

  return (
    <TravelerIconButton isSaving={isSubmitting}>
      <BadgeHover
        badgeContent={formErrors.length}
        invisible={formErrors.length === 0}
        color="error"
        onClick={onBadgeClick}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {
          <CustomIconButton
            Icon={isSubmitting ? SyncRounded : SaveRounded}
            color={formErrors.length === 0 ? 'success' : 'error'}
            onClick={onIconClick}
            variant="outlined"
            disabled={isSubmitting || !isConnected}
          />
        }
      </BadgeHover>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitleContainer>
          Erreurs de validation du rapport
          <CloseButton handleClose={() => setOpenModal(false)} />
        </DialogTitleContainer>
        <DialogContentContainer>
          {
            formErrors.map((error, index) => (
              <ErrorMessageSeparatorContainer key={`${error.msg}-${index}`}>
                <ErrorMessageContainer>
                  <ShowMoreLessText
                    strLenLimit={9999}
                    content={error.msg}
                  />
                  {
                    error.url &&
                      <Button
                        onClick={() => onButtonClick(error.url)}
                        variant="contained"
                      >
                        Corriger
                      </Button>
                  }
                </ErrorMessageContainer>
                {index !== formErrors.length - 1 && <BoldSeparator />}
              </ErrorMessageSeparatorContainer>
            ))
          }
        </DialogContentContainer>
      </Dialog>
    </TravelerIconButton>
  )
}

export default ReportSyncStatusButton
